//slidemenu
.p-slidemenu {
    .p-menuitem-link {
        border: $menuitemBorder;

        &:focus {
            border-color: $menuitemFocusBorderColor;
        }
    }
}
