@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/flag-icons/css/flag-icons.min.css';
@import 'assets/layout/css/needed.css';

@import 'assets/baywarelusy/baywarelusy';

#spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  height:60px;
  width:60px;
  margin:0px auto;
}

.wordrap {
  white-space: pre-wrap;
}
