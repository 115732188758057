.customer-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.customer-badge.status-completed {
  background: #C8E6C9;
  color: #256029;
}

.customer-badge.status-draft {
  background: #FFCDD2;
  color: #C63737;
}

.customer-badge.status-Approved {
  background: #78B90F;
  color: #3f5e0c;
}

.customer-badge.status-Draft {
  background: #F9D900;
  color: #8e7b00;
}

.customer-badge.status-Rejected {
  background: #D7373C;
  color: #611a1c;
}

.customer-badge.status-valid {
  background: #C8E6C9;
  color: #256029;
}

.p-chips > ul.ui-inputtext,
.p-chips > ul.ui-inputtext:focus {
  border: none;
  box-shadow: none;
  background: none;
}

.p-chips .p-chips-input-token {
  display: none;
}

.product {
  color: #00D0DE;
}

.customer-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}
.customer-badge.status-Closed {
  background: #C8E6C9;
  color: #256029;
}
.customer-badge.status-draft {
  background: #FFCDD2;
  color: #C63737;
}
.customer-badge.status-Billed {
  background: #78B90F;
  color: #3f5e0c;
}
.customer-badge.status-Pending.Fulfillment {
  background: #78B90F;
  color: #3f5e0c;
}
.customer-badge.status-Pending.Approval {
  background: #78B90F;
  color: #3f5e0c;
}
.customer-badge.status-Draft {
  background: #F9D900;
  color: #8e7b00;
}
