@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin list-item-hover {
    background-color: $listItemHoverBgColor;
    color: $listItemHoverTextColor;
}

@mixin list-item-highlight {
    background-color: $primaryColor;
    color: $highlightTextColor;
}

@mixin menu-item-hover {
    background-color: $primaryColor;
    color: $highlightTextColor;
}

@mixin background-gradient-top2bottom($start-colour, $end-colour) {
    background-color: $start-colour;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:         linear-gradient(to bottom, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-colour}', endColorstr='#{$end-colour}');
}

@mixin background-gradient-bottom2top($start-color, $end-color) {
    background-color: $end-color;
    background-image: -webkit-gradient(linear, left bottom, left top, from($start-color), to($end-color));
    background-image: -webkit-linear-gradient(to top, $start-color, $end-color);
    background-image:    -moz-linear-gradient(to top, $start-color, $end-color);
    background-image:     -ms-linear-gradient(to top, $start-color, $end-color);
    background-image:      -o-linear-gradient(to top, $start-color, $end-color);
    background-image:         linear-gradient(to top, $start-color, $end-color);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-color}', endColorstr='#{$end-color}');
}

@mixin border-shadow() {
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

@mixin overlay-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

@mixin link-focus-shadow() {
    -webkit-box-shadow: 0 0 4px 0 $primaryColor;
    -moz-box-shadow: 0 0 4px 0 $primaryColor;
    box-shadow: 0 0 4px 0 $primaryColor;
}
@mixin flex() {
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-wrap($value) {
    -ms-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin flex-direction-row() {
    -ms-flex-direction: row;
    flex-direction: row;
}

@mixin flex-direction-column() {
    -ms-flex-direction: column;
    flex-direction: column;
}

@mixin flex-justify-center() {
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin flex-justify-between() {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@mixin flex-align-center() {
    -ms-flex-align: center;
    align-items: center;
}

@mixin flex-align-start() {
    -ms-flex-align: start;
    align-items: flex-start;
}

@mixin flex-align-end() {
    -ms-flex-align: end;
    align-items: flex-end;
}

@mixin flex-justify-start() {
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@mixin flex-justify-end() {
    -ms-flex-pack: end;
    justify-content: flex-end;
}

@mixin nested-submenu-indents($val, $index, $length) {
    ul {
        li {
            a {
                padding-left: $val * $index * 1px;
            }

            @if $index < $length {
                @include nested-submenu-indents($val, $index + 1, $length);
            }
        }
    }
}

@mixin reset-list {
    margin: 0;
    padding: 0;
    outline: 0;
    overflow: hidden;
    list-style-type: none;
}
