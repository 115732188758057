html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding:0;
    margin:0;
    min-height: 100%;
    background: $bodyBg;
    background-repeat: no-repeat;
    background-attachment: fixed;

    a {
        color: $primaryColor;
        text-decoration: none;
    }
}

.layout-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    background: $bodyImage;
    background-repeat: no-repeat;

    .layout-main {
        @include flex();
        @include flex-direction-column();
        @include flex-justify-between();
        min-height: 100vh;
        @include transition(margin-left .3s);

        .layout-main-content {
            flex: 1 1 0;
            padding: 30px;
        }
    }
}
