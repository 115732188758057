// color definition
$primaryColor: #78B90F;
$primaryHoverColor: #669d0c;
$secondaryColor: #F9D900;
$tertiaryColor: #008C46;
$alertColor: #D7373C;

$grey1: #f3f3f3;
$grey2: #e3e3e3;
$grey3: #cccccc;
$grey4: #b2b2b2;
$grey5: #999999;
$grey6: #7f7f7f;
$grey7: #666666;
$grey8: #4c4c4c;
$grey9: #2e2e2e;

$white: #ffffff;

$primaryLightColor: scale-color($primaryColor, $lightness: 75%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -25%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -50%) !default;
$primaryTextColor: #000000;

$highlightBg: $primaryColor;
$highlightTextColor: $primaryTextColor;


// Buttons
//$buttonTextColor: $white;
//$buttonBg: $primaryColor;
//$buttonTextHoverColor: $white;
//$buttonHoverBg: $primaryHoverColor;
//$buttonHoverBorderColor: $primaryHoverColor;
//$buttonPadding: 0.8rem 1.2rem !default;

//$secondaryButtonTextColor: $primaryTextColor;
//$secondaryButtonBg: $secondaryColor;
//$secondaryButtonBorder: $secondaryColor;
//$secondaryButtonHoverBg: scale-color($secondaryColor, $lightness: -25%);
//$secondaryButtonTextHoverColor: $primaryTextColor;
//$secondaryButtonHoverBorderColor: scale-color($secondaryColor, $lightness: -25%);
//
//$dangerButtonTextColor: $primaryTextColor;
//$dangerButtonBg: $alertColor;
//$dangerButtonBorder: $alertColor;
//$dangerButtonHoverBg: scale-color($alertColor, $lightness: -25%);
//$dangerButtonTextHoverColor: $primaryTextColor;
//$dangerButtonHoverBorderColor: scale-color($alertColor, $lightness: -25%);


// Menu
$menuBg: $grey8;

$rootMenuItemTextColor: $white !default;
$rootMenuItemHoverTextColor: $primaryHoverColor !default;
$rootMenuItemHoverBg: transparent !default;
$activeRootMenuItemTextColor: $primaryColor !default;

$submenuItemTextColor: $white !default;
$submenuItemHoverTextColor: $primaryHoverColor !default;
$submenuItemHoverBg: $grey7 !default;
$activeSubmenuItemTextColor: $primaryColor !default;


// Toast
$infoMessageBg: $white !default;
$infoMessageBorder: solid $tertiaryColor !default;
$infoMessageTextColor: $primaryTextColor !default;
$infoMessageIconColor: $tertiaryColor !default;
$successMessageBg: scale-color($primaryColor, $lightness: 75%) !default;
$successMessageBorder: solid $primaryColor !default;
$successMessageTextColor: $primaryTextColor !default;
$successMessageIconColor: $primaryColor !default;
$warningMessageBg: scale-color($secondaryColor, $lightness: 75%) !default;
$warningMessageBorder: solid $secondaryColor !default;
$warningMessageTextColor: $primaryTextColor !default;
$warningMessageIconColor: $primaryTextColor !default;
$errorMessageBg: scale-color($alertColor, $lightness: 75%) !default;
$errorMessageBorder: solid $alertColor !default;
$errorMessageTextColor: $primaryTextColor !default;
$errorMessageIconColor: $primaryTextColor !default;

$colors: (
  primary: $primaryColor,
  secondary: $secondaryColor
);

// Import theme styling
@import '../sass/layout/layout_light';
@import '../sass/theme/theme_light';

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $primaryColor #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: $primaryColor;
  border-radius: 5px;
  border: none;
}

// Override some CSS
//.p-button {
//    border-radius: 2rem;
//}

.p-confirm-dialog-reject {
  @extend .p-button-danger;
}

.layout-wrapper {
  background: none;
}

.layout-menu-wrapper {
  border-radius: 0px !important;

  .b-icon::before{
    filter: brightness(0) invert(1);
  }
}

.layout-wrapper.layout-sidebar .layout-menu-wrapper .menu-logo {
  padding: 10px;
}

.layout-menu-wrapper .menu-logo .logo img {
  height: 5rem;
}

.layout-menu-wrapper .menu-logo .app-name img {
  padding: 0;
  height: 5rem;
  margin-left: -1.5rem;
}

.layout-menu-wrapper .layout-menu > li.active-menuitem > a {
  color: $primaryColor !important;

  i {
    color: $primaryColor !important;
  }

  .b-icon::before{
    filter: none;
  }
}

.layout-menu-wrapper .layout-menu > li.active-menuitem:has(> ul) {
  border: 1px solid $grey7;
}

.layout-menu-wrapper .layout-inline-menu {
  .layout-inline-menu-action,
  .layout-inline-menu-action-panel .layout-inline-menu-action-item a {
    color: $white;
  }
}

.layout-footer .footer-logo-container img {
  height: 4rem;
  width: auto;
}


// Panelmenu
.p-panelmenu {
  .b-icon::before{
    filter: grayscale(100);
  }

  .p-panelmenu-header.p-highlight > a{
    background-color: transparent;
  }
}
