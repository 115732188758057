//tieredmenu
.p-tieredmenu {
    .p-submenu-list {
        border-radius: $borderRadius;
    }

    .p-menuitem-link {
        border: $menuitemBorder;

        &:focus {
            border-color: $menuitemFocusBorderColor;
        }
    }
}
