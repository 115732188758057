//menu
.p-menu {
    .p-submenu-header {
        font-size: $submenuHeaderFontSize;
    }

    .p-menuitem-link {
        border: $menuitemBorder;

        &:focus {
            border-color: $menuitemFocusBorderColor;
        }
    }
}
