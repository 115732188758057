.layout-inline-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 0 none;
    padding-bottom: 1.5rem;

    &.layout-inline-menu-active {
        .layout-inline-menu-icon {
            transform: rotate(-180deg);
        }
    }

    .layout-inline-menu-action {
        cursor: pointer;
        padding: 2rem 2rem 1rem 2rem;
        color: $textColor;
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
            border-radius: 22px;
            border: 2px solid transparent;
        }

        .layout-inline-menu-text {
            font-weight: 500;
            display: flex;
            flex-direction: column;
            margin-left: .5rem;
        }

        .layout-inline-menu-icon {
            margin-left: auto;
        }
    }

    .layout-inline-menu-action-panel {
        padding: 0 1.5rem;

        .layout-inline-menu-action-item {
            border-radius: $borderRadius;
            transition: all $transitionDuration;
            cursor: pointer;
            user-select: none;
            margin-top: .2rem;

            &:last-child {
                margin-bottom: .5rem;
            }

            a {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 0.5rem 1rem;
                border-left: 8px solid transparent;
                border-radius: 8px;
                color: $textColor;

                i {
                    margin-right: .75rem;
                }
            }
        }
    }
}
