//checkbox
.p-checkbox {
    &:not(.p-checkbox-disabled) {
        .p-checkbox-box {
            &:hover {
                border-color: $checkboxHoverBorderColor;
            }
        }
    }
}
