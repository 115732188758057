@import 'common';

$bodyBg: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%) !default;
$bodyImage: None;//  url("../../../../../../../angular/wms-frontend-master/wms-frontend-master/src/assets/layout/images/effect-onlight.png") !default;
$textColor:#44486D !default;
$textSecondaryColor:#676B89 !default;
$textThirdColor: #8A8EA6 !default;
$dividerColor:rgba(68, 72, 109, 0.2) !default;

//loader
$loaderPathColor:#0A061A !default;

//card
$cardBg: rgba(255, 255, 255, 0.9) !default;
$cardBorder: 0 none !default;

//topbar
$topbarTextColor: $textSecondaryColor !default;
$topbarTextHoverColor: $textColor !default;
$topbarIconBg: rgba(68, 72, 109, 0.07) !default;
$topbarIconHoverBg: rgba(68, 72, 109, 0.12) !default;

$topbarSubmenuBg: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%)!default;
$topbarSubmenuItemHoverBgColor: rgba(68, 72, 109, 0.07) !default;

//menu
$menuWidth: 21rem !default;
$slimMenuWidth: 5.857rem !default;
$sidebarInactiveMenuWidth: 6.571rem !default;
$menuBg: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%) !default;
$menuIconBg: rgba(68, 72, 109, 0.07) !default;
$menuIconHoverBg: rgba(68, 72, 109, 0.12) !default;
$menuIconTextColor: $textSecondaryColor !default;
$menuIconHoverTextColor: $textColor !default;

$rootMenuItemTextColor: $textSecondaryColor !default;
$rootMenuItemHoverTextColor: $textColor !default;
$rootMenuItemHoverBg: rgba(68, 72, 109, 0.07) !default;
$activeRootMenuItemTextColor: $primaryColor !default;

$submenuItemTextColor: $textColor !default;
$submenuItemHoverTextColor: $textColor !default;
$submenuItemHoverBg: rgba(68, 72, 109, 0.07) !default;
$activeSubmenuItemTextColor: $primaryColor !default;

$overlayMenuActiveBg: rgba(68, 72, 109, 0.07) !default;
$overlayMenuItemActiveBg: $primaryColor !default;
$overlayMenuItemActiveTextColor: $primaryTextColor !default;

$slimRootMenuItemIconColor: $textColor !default;
$slimActiveRootMenuItemBgColor: $primaryColor !default;
$slimActiveRootMenuItemTextColor: $primaryTextColor !default;
$slimMenuItemTextColor: $textColor !default;

//inline menu
$inlineMenuImageHoverBorderColor: #8A8EA6 !default;

//rightmenu
$rightMenuWidth: 24.571rem !default;
$rightMenuBg: linear-gradient(180deg, #F6F9FC 0%, #ECECF9 100%) !default;
$rightMenuButtonBg: rgba(68, 72, 109, 0.07) !default;
$rightMenuListItemBg: rgba(68, 72, 109, 0.07) !default;

//dashboard
$progressBarBg: rgba(68, 72, 109, 0.12) !default;

//pages
$helpHeaderBg: linear-gradient(179.2deg, rgba(15, 139, 253, 0.15) 0.69%, rgba(15, 139, 253, 0) 108.44%), rgba(255, 255, 255, 0.9) !default;
$helpHeaderImage: None !default;
$exceptionPagesImage: None !default;
